/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-19 17:08:14
 * @FilePath: /apiForManage/mediaAccount.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  contractPage: '/dspSettle/contractPage',
  getContractById: '/dspSettle/getContractById',
  updateContractSts: 'dspSettle/updateContractSts',
  getDspListByBudget: '/budget/dspListByBudget',
  addContract: '/dspSettle/addContract',
  updateContract: '/dspSettle/updateContract',

  fundPage: '/dspSettle/fundPage',
  addFund: '/dspSettle/addFund',
  updateFundSts: '/dspSettle/updateFundSts',
  fundDetails: '/dspSettle/fundDetails'
}

export default api
export function contractPage (data) {
  return request({
    url: api.contractPage,
    method: 'post',
    data
  })
}
export function getContractById (data) {
  return request({
    url: api.getContractById,
    method: 'post',
    data
  })
}
export function updateContractSts (data) {
  return request({
    url: api.updateContractSts,
    method: 'post',
    data
  })
}
export function addContract (data) {
  return request({
    url: api.addContract,
    method: 'post',
    data
  })
}
export function updateContract (data) {
  return request({
    url: api.updateContract,
    method: 'post',
    data
  })
}
export function fundPage (data) {
  return request({
    url: api.fundPage,
    method: 'post',
    data
  })
}
export function addFund (data) {
  return request({
    url: api.addFund,
    method: 'post',
    data
  })
}
export function updateFundSts (data) {
  return request({
    url: api.updateFundSts,
    method: 'post',
    data
  })
}
export function fundDetails (data) {
  return request({
    url: api.fundDetails,
    method: 'post',
    data
  })
}
