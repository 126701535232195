<template>
  <a-modal
    v-model="addFundVisible"
    :title="title"
    width="1100px"
  >
    <div class="fund-modal-container">
      <a-form-model
        v-if="isShow !== 3"
        ref="ruleForm"
        :model="formquery"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 10 }">
        <a-form-model-item
          label="部署平台"
          prop="partnerIdList">
          <a-select
            mode="multiple"
            disabled
            v-model="formquery.partner"
            placeholder="请选择部署平台"
          >
            <a-select-option v-for="(item, index) in partnerIdList" :key="index" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="公司名称"
          :rules="[{ required: true, message: '请选择公司名称', trigger: 'blur' }]"
          prop="trafficUserId"
        >
          <a-select
            class="search_item"
            :allowClear="true"
            v-model="formquery.trafficUserId"
            placeholder="请选择公司名称"
            disabled
            showSearch
          >
            <a-select-option v-for="item in trafficUserList" :key="item.id" :value="item.trafficUserId">{{ item.company }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '请输入收款金额', trigger: 'blur' }]"
          label="收款金额"
          prop="amount"
        >
          <a-input v-model.trim="formquery.amount" placeholder="请输入收款金额" />
        </a-form-model-item>
        <a-form-model-item label="收款方式" :rules="[{ required: true, message: '请选择收款方式', trigger: 'blur' }]" prop="type">
          <a-select
            class="search_item"
            :allowClear="true"
            placeholder="请选择收款方式"
            v-model="formquery.type"
            showSearch
          >
            <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="入账状态" :rules="[{ required: true, message: '请选择入账状态', trigger: 'blur' }]" prop="sts">
          <a-select
            class="search_item"
            :allowClear="true"
            placeholder="请选择入账状态"
            v-model="formquery.sts"
            showSearch
          >
            <a-select-option v-for="item in stsList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="edit-wrapper" v-if="isShow === 3">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="createTime" label="入账时间"> </el-table-column>
          <el-table-column prop="company" label="公司名称"> </el-table-column>
          <el-table-column prop="aggregateAmount" label="入账金额">
            <template slot-scope="{row}">
              <div>{{ moneyText }}{{ numFormat(row.amount || 0, 0) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="sts" label="入账状态">
            <template slot-scope="{row}">
              <div>{{ row.sts ? '已入账' : '未入账' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="收款方式">
            <template slot-scope="{row}">
              <div>{{ row.type ? '后付费' : '预付费' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <div class="action-slot" slot-scope="{row}">
              <span class="btn" @click="handleEdit(row)">修改入账状态</span>
            </div>
          </el-table-column>
          <template slot="empty">
            <m-empty style="height: 341px" imgHeight="176px">
              <template #description>暂时没有任何数据</template>
            </m-empty>
          </template>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0 && isShow === 3"
          show-size-changer
          :current="query.page"
          :pageSize="query.limit"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <template slot="footer">
      <a-button key="back" @click="handleCancel" v-if="isShow === 3 || isShow === 1"> 取消 </a-button>
      <a-button key="back" @click="handleBack" v-if="isShow === 2"> 上一步 </a-button>
      <a-button key="submit" v-if="isShow !== 3" type="primary" @click="handleSubmit"> 确定 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { getTrafficUserInfo } from '@/apiForManage/common'
import { addFund, updateFundSts, fundDetails } from '@/apiForManage/mediaAccount'
import { numFormat } from '@/utils/dealNumber'
import { partnerIdList } from '@/data/dataList'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'

export default {
  mixins: [rateAndMoneyMethod],
  data () {
    return {
      query: {
        page: 1,
        limit: 10
      },
      formquery: {
        trafficUserId: undefined,
        amount: undefined,
        type: undefined,
        sts: undefined
      },
      rangePickerDate: [],
      // 回显列表
      editList: [6],
      // 公司option
      trafficUserList: [],
      typeList: [
        { name: '预付费', id: 0 },
        { name: '后付费', id: 1 }
      ],
      stsList: [
        { name: '未入账', id: 0 },
        { name: '已入账', id: 1 }
      ],
      partnerIdList,
      title: '',
      tableData: [],
      isShow: false,
      total: 0
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 9
    },
    fundInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    isShow: {
      handler (val) {
        const list = {
          1: '新建入账',
          2: '修改入账状态',
          3: '查看入账记录'
        }
        this.title = list[val]
      },
      deep: true,
      immediate: true
    },
    visible: {
      handler (val) {
        if (val) {
        }
      }
    }
  },
  computed: {
    addFundVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  async created () {
    this.isShow = this.isAdd
    this.formquery.partner = [String(this.fundInfo.partner)]
    this.formquery.trafficUserId = this.fundInfo.trafficUserId
    this.getTrafficUserList()
    if (this.isAdd === 3) this.fundDetails()
  },
  methods: {
    numFormat,
    changePartnerIdList () {
      this.getTrafficUserList()
    },
    handleChangePage (page) {
      this.query.page = page
      this.fundDetails()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.fundDetails()
    },
    handleEdit (data) {
      this.formquery = JSON.parse(JSON.stringify(data))
      this.formquery.partner = [String(JSON.parse(JSON.stringify(data)).partner)]
      this.isShow = 2
    },
    // 公司option
    async getTrafficUserList () {
      const obj = {
        partnerIdList: this.formquery.partnerIdList
      }
      const { data = [] } = await getTrafficUserInfo(obj)
      this.trafficUserList = data
    },
    // 入账记录
    async fundDetails () {
      const { data = [] } = await fundDetails({
        trafficUserId: this.fundInfo.trafficUserId,
        page: this.query.page,
        limit: this.query.limit
      })
      this.total = data.total
      this.tableData = data.items
    },
    handleCancel () {
      this.addFundVisible = false
    },
    handleBack () {
      this.isShow = 3
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const list = {
            1: addFund,
            2: updateFundSts
          }
          delete this.formquery.partner
          const resp = await list[this.isShow](this.formquery)
          if (resp.code === 200) {
            if (this.isShow === 2) {
              this.fundDetails()
              this.isShow = 3
            } else {
              this.addFundVisible = false
              this.$emit('handleChange')
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fund-modal-container {
  height: 400px;
  overflow-y: auto;
  padding-top: 10px;
}
.contral-box {
  display: flex;
  justify-content: flex-start;
}
.add-wrapper,
.edit-wrapper {
  padding: 5px 20px;
  margin-bottom: 20px;
  .add-item,
  .edit-item {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    &:last-child {
      border: none;
    }
  }
  ::v-deep {
    .el-table__empty-block {
      border-bottom: 1px solid #EBEEF5;
    }
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 10px;
}
.info-box {
  display: flex;
  justify-content: space-between;
  .copy {
    color: @primary-color;
    cursor: pointer;
    padding: 0 5px;
  }
}
.btn {
  color: @primary-color;
  cursor: pointer;
}
.look {
  cursor: pointer;
}
.pagination{
  padding: 10px 0;
}
</style>
